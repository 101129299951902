import { useAuthenticationService } from 'admin-portal-shared-services';
import { v4 as guid } from 'uuid';
import { UserConfig, useUserConfigService } from 'grow-shared-services';
import { LOCAL_STORAGE_PORTAL_CONFIG } from '../constants';

export const getPortalConfig = (): Record<string, string> => {
  const portalLocalStorage = localStorage.getItem(LOCAL_STORAGE_PORTAL_CONFIG);

  if (!portalLocalStorage) return undefined;

  return JSON.parse(portalLocalStorage);
};

export const getTokenLocalStorage = (): string => {
  return useAuthenticationService().getAuthHeader();
};

export const getRequestTraceId = (): string => {
  return guid();
};

export const getUserInfos = (): UserConfig => {
  const { data } = useUserConfigService().getStoreValue();

  return { ...data };
};

export const getEuropeClusterOrUrlParam = (url: string) => {
  const { country } = getUserInfos();

  const europeCountries = localStorage.getItem('europeCountries');

  if (europeCountries?.includes(country)) {
    return process.env.EUROPE_HOST + removeUrlPrefix(url);
  }

  return url;
};

const removeUrlPrefix = (url: string) => {
  return url.replace('/api/v1', '');
};

interface Navigation {
  fromButtonName: string;
  toButtonName: string;
  fromURI: string;
  toURI: string;
  uri: string;
  event: React.SyntheticEvent;
  navigate: () => void;
}

export const handleNavigationEvent = ({
  fromButtonName,
  toButtonName,
  fromURI,
  navigate,
  uri,
  event,
}: Navigation) => {
  if (toButtonName !== fromButtonName && uri === fromURI) {
    event.preventDefault();
    const exitPage = new CustomEvent('exitPageBySideMenuEvent', {
      detail: {
        navigate,
        destination: uri,
      },
    });
    window.dispatchEvent(exitPage);
  }
};

export const getFormattedDateByCountry = (date: Date): string => {
  const { preferredLanguage, country } = getUserInfos();
  const outputDate = date.getDate();
  const outputMonth = GetAbbreviatedMonth(preferredLanguage, date.getMonth());
  const outputYear = date.getFullYear();

  return country === COUNTRY_VALUES.US || country === COUNTRY_VALUES.ZA
    ? `${outputYear}, ${outputMonth} ${outputDate}`
    : `${outputDate} ${outputMonth}, ${outputYear}`;
};

const MONTHS = {
  'pt-BR': ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  'es-419': ['Ene', 'Feb', 'Mar', 'Abr', 'Mayo', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'],
  default: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
};

function GetAbbreviatedMonth(language: string, monthNumber: number): string {
  const monthNames = MONTHS[language] || MONTHS.default;
  return monthNames[monthNumber];
}

export enum COUNTRY_VALUES {
  US = 'US',
  BR = 'BR',
  DO = 'DO',
  EC = 'EC',
  CO = 'CO',
  ZA = 'ZA',
  PE = 'PE',
  MX = 'MX',
  PA = 'PA',
  HN = 'HN',
  SV = 'SV',
  UY = 'UY',
  PY = 'PY',
  AR = 'AR',
}
