import { createGlobalStyle } from 'styled-components';
import palette from './palette';

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Work Sans', sans-serif !important;

    ::-webkit-scrollbar {
      width: 14px !important;
    }

    ::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0) !important;
      background-clip: padding-box !important;
      border-radius: 0.625rem !important;
      background-color: ${palette.grey.border} !important;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Barlow' !important;
  }
`;

export { GlobalStyle };
