const palette = {
  white: '#FFFFFF',
  black: '#000000',
  green: '#1F8E4A',
  grey: {
    light: 'rgb(233, 233, 233)',
    border: '#DBDADA',
  },
  primary: {
    main: '#FFFF00',
  },
  secondary: {
    main: '#757575',
    light: '#F2F2F2',
  },
  info: {
    main: '#0363C4',
    secondary: '#E9F1FF',
    error: '#C9201D',
  },
  paper: {
    grey: '#DBDADA',
    light: '#F5F2FD',
    grow: '#1af07e',
    boxShadow: 'rgba(0, 0, 0, 0.16)',
  },
  font: {
    dark: '#141414',
  },
};

export default palette;
