import React from 'react';
import { Toast } from '@hexa-ui/components';

interface ToastProps {
  message: string;
  type: 'error' | 'success' | 'info' | 'warning';
  open: boolean;
  setOpen: (open: boolean) => void;
  action?: () => void;
  actionMessage?: string;
}

export const ToastComponent = ({
  message,
  type,
  action,
  actionMessage,
  open,
  setOpen,
}: ToastProps) => {
  return (
    <Toast.Provider swipeDirection="right" duration={3000}>
      <Toast.Root
        type={type}
        open={open}
        onClose={() => setOpen(false)}
        onOpenChange={setOpen}
        showCloseButton
        position="top-right"
        message={message}
        actions={[
          {
            action: () => {
              action?.();
              setOpen(false);
            },
            name: actionMessage,
          },
        ]}
        data-testid={`toast-${message}`}
      />
    </Toast.Provider>
  );
};
