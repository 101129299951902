import MessageMap from '../i18n';

export const es419: MessageMap = {
  TEST: {
    TRANSLATE: 'traducción',
  },
  UNGROUPED_AGENTS_CARD: {
    UNGROUPED_AGENTS_WARNING_MESSAGE:
      'Hay algunos agentes que pueden necesitar asignación a grupos',
    RELOCATE_BUTTON: 'Trasladarse',
    SELECT_ALL_BUTTON: 'Seleccionar todo',
    SELECTED_TEXT: 'seleccionado',
    UNGROUPED_AGENTS_TEXT: 'Agentes no agrupados',

    NO_AGENTS: 'No se encontraron agentes...',
    NO_AGENTS_UNGROUPED: 'Parece que no hay agentes desagrupados.',
    NO_AGENTS_DESCRIPTION: 'Esta página se completará una vez que estén disponibles.',

    ERROR_SERVICE: 'Servicio no disponible...',
    ERROR_SERVICE_DESCRIPTION:
      'No pudimos cargar la lista de agentes desagrupados. Por favor actualice la lista.',
    REFRESH: 'Actualizar lista',
  },
  LOADING: {
    BEELOADING_TEXT: 'Procesando…',
  },
  TEAMS_MAIN_PAGE: {
    TITLE: 'Equipos',
    CREATE_GROUP: 'Crear nuevo grupo',
    NO_GROUPS_CREATED: 'Aún no se han creado grupos.',
    NO_GROUPS_CREATED_DESCRIPTION:
      'Los grupos permiten configurar la audiencia, las misiones y más para múltiples agentes.',
    CANCEL: 'Cancelar',
    CREATE: 'Crear',

    PAGINATION_INFO: 'Mostrando Y de X grupos por página',
  },
  ORGANIZATION_CARD: {
    VENDORS: 'Vendedores',
    ORG_INFO: {
      AGENTS: 'Agentes',
      GROUPS: 'Grupos',
      MISSIONS: 'Misiones',
      TOOLTIP_INFO_MESSAGE: 'Total de misiones activas para la organización',
    },
    MY_BEES_DATA: 'My BEES Data',
    STRUCTURES: 'Estructuras',
  },
  GROUP_CARD: {
    AGENTS: 'Agentes',
    MISSIONS: 'Misiones',
    VENDORS: 'Vendors',
  },
  CREATE_GROUP_FORM: {
    FIELD_NAME: 'Nombre',
    FIELD_DESCRIPTION: 'Describa este grupo en pocas palabras',
    FIELD_PLACEHOLDER: 'Ejemplo: crecimiento de socios',
    FIELD_GROUP_NAME_REQUIRED: 'Ingresa un nombre',
    FIELD_GROUP_NAME_MUST_BE_UNIQUE: 'Ya existe un grupo con este nombre, prueba con otro.',

    OPTIONAL: '(opcional)',
    REMOVE_ALL: 'Eliminar todo',
    USE_FIELD_ABOVE: 'Utilice el campo de arriba para agregar.',

    FIELD_AGENT: 'Agentes',
    FIELD_AGENT_TITLE: 'Seleccionar agente',
    FIELD_AGENT_PLACEHOLDER: 'Buscar por nombre o correo electrónico',
    FIELD_AGENT_EMPTY: 'Aún no hay agentes.',
    FIELD_AGENT_NO_UNGROUPED_AGENTS: 'Todos los agentes ya están asignados.',
    FIELD_AGENT_NO_UNGROUPED_AGENTS_DESCRIPTION:
      'Puede mover agentes desde los detalles de su grupo actual.',

    FIELD_MISSION: 'Misiones',
    FIELD_MISSION_TITLE: 'Agregar misiones',
    FIELD_MISSION_EMPTY: 'Aún no hay misiones. ',
    FIELD_MISSION_SEARCH: 'Buscar misión por nombre',
    FIELD_MISSION_ERROR:
      'Ha alcanzado el límite de 50 misiones. Agrupe todas las misiones locales o distribuya las misiones en grupos.',

    FIELD_AUDIENCE: 'Audiencia',
    FIELD_AUDIENCE_TITLE: 'Agregar vendor',
    FIELD_AUDIENCE_EMPTY: 'Aún no hay vendors.',
    FIELD_AUDIENCE_SEARCH: 'Buscar vendor por nombre o ID',
    FIELD_AUDIENCE_ERROR: 'Seleccione un vendor',
    FIELD_SEGMENT_ERROR: 'Inserta un valor o elimina la condición',

    FIELD_KPIS: 'Metas de desempeño',
    FIELD_KPIS_AHT: 'TMA',
    FIELD_KPIS_AHT_MEANING: 'Tiempo medio de atención',
    FIELD_KPIS_AHT_DETAILS_MEANING:
      '"Tiempo medio de atención". Calculado por el tiempo medio dedicado a las llamadas.',
    FIELD_KPIS_TOPLINE_UPLIFT: 'Topline recuperado ',
    FIELD_KPIS_TOPLINE_UPLIFT_MEANING:
      'Calculado por la cantidad recuperada y el porcentaje después de una llamada de strike y la colocación de un pedido real.',
    FIELD_KPIS_TOTAL_CALLS: 'Llamadas totales',
    FIELD_KPIS_TOTAL_CALLS_MEANING: 'Total de llamadas realizadas por el agente.',
    FIELD_KPIS_HIT_RATE: 'Contactabilidad',
    FIELD_KPIS_HIT_RATE_MEANING:
      'Calculado dividiendo el total de llamadas contestadas por el número total de llamadas realizadas.',
    FIELD_KPIS_VISIBLE: 'KPI visible ',
    FIELD_KPIS_INVISIBLE: 'KPI invisible',
    FIELD_KPIS_DAILY: 'A diario',
    FIELD_KPIS_MONTHLY: 'Mensual',
    FIELD_KPIS_TOOLTIP:
      'Define si este KPI será visible o no para los agentes seleccionados para este grupo.',
    FIELD_KPIS_EMPTY: 'Aún no hay metas.',

    ADD_ALL: 'Añadir todo',
    FIELD_SEARCH_NOT_FOUND: 'No se encontró nada con los términos buscados.',

    ADD_CONDITION: 'Añadir condición',
    ADD_VALIDATOR: 'Añadir validador',
    REMOVE_CONDITION: 'Eliminar condición',
    ENTER_VALIDATOR: 'Introducir valor',
    SEGMENT: 'Segmento',
    SEARCH_CONDITION: 'Buscar condición',

    TOPLINE_GAP: 'Topline gap',
    MARKETPLACE: 'Marketplace',
    CHALLENGES: 'Desafíos',
    SERVICES: 'Servicios',
    LOCAL_MISSIONS: 'Misiones locales',

    TOAST_SUCCESS_MESSAGE: 'Grupo creado con éxito.',
    TOAST_ERROR_MESSAGE: 'No se pudo crear el grupo.',
    TOAST_ERROR_INVALID_ORGANIZATION:
      'La organización actual ya no está disponible. Póngase en contacto con el soporte para obtener más información.',
    TOAST_ERROR_TRY_AGAIN: 'Intentar otra vez',

    TOGGLE_ALL_LOCAL_MISSIONS: 'Agrupar todas las Misiones Locales',
    TOOLTIP_ALL_LOCAL_MISSIONS:
      'Esta opción agrupa todas las misiones locales individuales en una sola, permitiéndole priorizarlas más rápidamente',
  },
  EDIT_GROUP_FORM: {
    TITLE: 'Editar grupo',
    SAVE: 'Guardar',
    CANCEL: 'Cancelar',

    TOAST_SUCCESS_MESSAGE: 'Los cambios se guardaron con éxito.',
    TOAST_ERROR_MESSAGE: 'No se pueden guardar los cambios.',
    TOAST_ERROR_TRY_AGAIN: 'Intentar otra vez',
  },
  GROUP_DETAILS: {
    EDIT: 'Editar',
    DELETE: 'Eliminar',
    START_DATE: 'Inicio',
    END_DATE: 'Final',
    END_DATE_VALUE: 'Sin fecha de finalización',
  },
  DELETE_GROUP: {
    DELETE: 'Eliminar',
    CONFIRMATION_DELETE: '¿Eliminar este grupo?',
    DELETE_MESSAGE: 'El grupo se eliminará inmediatamente y esta acción no se puede deshacer.',
    GO_BACK: 'Volver atrás',
    TOAST_SUCCESS_MESSAGE: 'Grupo eliminado correctamente.',
    TOAST_FAILED_MESSAGE: 'No se puede eliminar el grupo.',
    TRY_AGAIN: 'Inténtelo de nuevo',
  },

  ERROR_PAGE_NOT_LOADED: {
    TITLE: 'No se puede cargar la página',
    TEXT: 'Nuestros servidores están temporalmente ocupados. Inténtelo de nuevo en unos minutos.',
    BUTTON: 'Intentar otra vez',
  },
};
