import MessageMap from '../i18n';

export const ptBR: MessageMap = {
  TEST: {
    TRANSLATE: 'tradução',
  },
  LOADING: {
    BEELOADING_TEXT: 'Processando…',
  },
  UNGROUPED_AGENTS_CARD: {
    UNGROUPED_AGENTS_WARNING_MESSAGE:
      'Existem alguns agentes que podem precisar de alocação para grupos',
    RELOCATE_BUTTON: 'Realocar',
    SELECT_ALL_BUTTON: 'Selecionar tudo',
    SELECTED_TEXT: 'selecionado',
    UNGROUPED_AGENTS_TEXT: 'Agentes não agrupados',

    NO_AGENTS: 'Nenhum agente encontrado...',
    NO_AGENTS_UNGROUPED: 'Parece que não há agentes desagrupados.',
    NO_AGENTS_DESCRIPTION: 'Esta página será populada quando estiverem disponíveis.',

    ERROR_SERVICE: 'Serviço não disponível... ',
    ERROR_SERVICE_DESCRIPTION:
      'Não foi possível carregar a lista de agentes desagrupados. Atualize a lista.',
    REFRESH: 'Atualizar lista',
  },
  TEAMS_MAIN_PAGE: {
    TITLE: 'Times',
    CREATE_GROUP: 'Criar um novo grupo',
    NO_GROUPS_CREATED: 'Nenhum grupo criado ainda.',
    NO_GROUPS_CREATED_DESCRIPTION:
      'Os grupos permitem definir o público, missões e muito mais para vários agentes.',
    CANCEL: 'Cancelar',
    CREATE: 'Criar',

    PAGINATION_INFO: 'Exibindo Y de X grupos por página',
  },
  ORGANIZATION_CARD: {
    VENDORS: 'Fornecedores',
    ORG_INFO: {
      AGENTS: 'Agentes',
      GROUPS: 'Grupos',
      MISSIONS: 'Missões',
      TOOLTIP_INFO_MESSAGE: 'Total de missões ativas para a organização ',
    },
    MY_BEES_DATA: 'My BEES Data',
    STRUCTURES: 'Estruturas',
  },
  GROUP_CARD: {
    AGENTS: 'Agentes',
    MISSIONS: 'Missões',
    VENDORS: 'Vendors',
  },
  CREATE_GROUP_FORM: {
    FIELD_NAME: 'Nome',
    FIELD_DESCRIPTION: 'Descreva este grupo em poucas palavras',
    FIELD_PLACEHOLDER: 'Exemplo: Crescimento de Parceiros',
    FIELD_GROUP_NAME_REQUIRED: 'Insira um nome',
    FIELD_GROUP_NAME_MUST_BE_UNIQUE: 'Já existe um grupo com este nome, tente outro.',

    OPTIONAL: '(opcional)',
    REMOVE_ALL: 'Remover tudo',
    USE_FIELD_ABOVE: 'Use o campo acima para adicionar.',

    FIELD_AGENT: 'Agentes',
    FIELD_AGENT_TITLE: 'Selecionar agente',
    FIELD_AGENT_PLACEHOLDER: 'Buscar por nome ou email',
    FIELD_AGENT_EMPTY: 'Ainda não há agentes.',
    FIELD_AGENT_NO_UNGROUPED_AGENTS: 'Todos os agentes já estão alocados.',
    FIELD_AGENT_NO_UNGROUPED_AGENTS_DESCRIPTION:
      'Você pode mover agentes dos detalhes do grupo atual.',

    FIELD_MISSION: 'Missões',
    FIELD_MISSION_TITLE: 'Adicionar missões',
    FIELD_MISSION_EMPTY: 'Ainda não há missões.',
    FIELD_MISSION_SEARCH: 'Pesquisar missão por nome',
    FIELD_MISSION_ERROR:
      'Você atingiu o limite de 50 missões. Agrupe todas as missões locais ou distribua as missões em grupos.',

    FIELD_AUDIENCE: 'Audiência',
    FIELD_AUDIENCE_TITLE: 'Adicione vendor',
    FIELD_AUDIENCE_EMPTY: 'Ainda não há vendors.',
    FIELD_AUDIENCE_SEARCH: 'Pesquisar vendor por nome ou ID',
    FIELD_AUDIENCE_ERROR: 'Selecione um vendor',
    FIELD_SEGMENT_ERROR: 'Insira um valor ou remova a condição',

    FIELD_KPIS: 'Metas de desempenho',
    FIELD_KPIS_AHT: 'TMA',
    FIELD_KPIS_AHT_MEANING: 'Tempo médio de atendimento',
    FIELD_KPIS_AHT_DETAILS_MEANING:
      '"Tempo médio de atendimento". Calculado pelo tempo médio gasto em ligações.',
    FIELD_KPIS_TOPLINE_UPLIFT: 'Topline recuperado',
    FIELD_KPIS_TOPLINE_UPLIFT_MEANING:
      'Calculado pelo valor recuperado e porcentagem após uma chamada de strike e colocação de pedido real.',
    FIELD_KPIS_TOTAL_CALLS: 'Ligações totais',
    FIELD_KPIS_TOTAL_CALLS_MEANING: 'Total de chamadas realizadas pelo agente. ',
    FIELD_KPIS_HIT_RATE: 'Hit rate',
    FIELD_KPIS_HIT_RATE_MEANING:
      'Calculado dividindo o total de chamadas atendidas pelo total de chamadas realizadas.',
    FIELD_KPIS_VISIBLE: 'KPI visível',
    FIELD_KPIS_INVISIBLE: 'KPI invisível',
    FIELD_KPIS_DAILY: 'Diário',
    FIELD_KPIS_MONTHLY: 'Mensal',
    FIELD_KPIS_TOOLTIP:
      'Defina se este KPI ficará visível ou não para os agentes selecionados para este grupo.',
    FIELD_KPIS_EMPTY: 'Ainda não há metas.',

    FIELD_SEARCH_NOT_FOUND: 'Nada foi encontrado com os termos pesquisados. ',

    ADD_ALL: 'Adicionar todos',

    ADD_CONDITION: 'Adicionar condição',
    ADD_VALIDATOR: 'Adicionar validador',
    REMOVE_CONDITION: 'Remover condição',
    ENTER_VALIDATOR: 'Inserir valor',
    SEGMENT: 'Segmento',
    SEARCH_CONDITION: 'Pesquisar condição',

    TOPLINE_GAP: 'Topline gap',
    MARKETPLACE: 'Marketplace',
    CHALLENGES: 'Desafios',
    SERVICES: 'Serviços',
    LOCAL_MISSIONS: 'Missões locais',

    TOAST_SUCCESS_MESSAGE: 'Grupo criado com sucesso.',
    TOAST_ERROR_MESSAGE: 'Não foi possível criar o grupo.',
    TOAST_ERROR_INVALID_ORGANIZATION:
      'A organização atual não está mais disponível. Entre em contato com o suporte para mais informações.',
    TOAST_ERROR_TRY_AGAIN: 'Tentar novamente',

    TOGGLE_ALL_LOCAL_MISSIONS: 'Agrupar todas as Missões Locais',
    TOOLTIP_ALL_LOCAL_MISSIONS:
      'Esta opção agrupa todas as missões locais individuais em uma só, permitindo que você as priorize mais rapidamente',
  },
  EDIT_GROUP_FORM: {
    TITLE: 'Editar grupo',
    SAVE: 'Salvar',
    CANCEL: 'Cancelar',

    TOAST_SUCCESS_MESSAGE: 'As alterações foram salvas com sucesso.',
    TOAST_ERROR_MESSAGE: 'Não foi possível salvar as alterações.',
    TOAST_ERROR_TRY_AGAIN: 'Tentar novamente',
  },
  GROUP_DETAILS: {
    EDIT: 'Editar',
    DELETE: 'Excluir',
    START_DATE: 'Início',
    END_DATE: 'Final',
    END_DATE_VALUE: 'Sem data final',
  },

  DELETE_GROUP: {
    DELETE: 'Excluir',
    CONFIRMATION_DELETE: 'Excluir este grupo?',
    DELETE_MESSAGE: 'O grupo será excluído imediatamente e esta ação não pode ser desfeita.',
    GO_BACK: 'Voltar',
    TOAST_SUCCESS_MESSAGE: 'Grupo excluído com sucesso.',
    TOAST_FAILED_MESSAGE: 'Não é possível excluir o grupo.',
    TRY_AGAIN: 'Tentar novamente',
  },

  ERROR_PAGE_NOT_LOADED: {
    TITLE: 'Não foi possível carregar a página',
    TEXT: 'Nossos servidores estão temporariamente ocupados. Por favor, tente novamente em alguns minutos.',
    BUTTON: 'Tente novamente',
  },
};
