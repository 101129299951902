import React from 'react';
import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { useFeatureToggleService } from 'admin-portal-shared-services';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@hexa-ui/theme';
import { InitialDataWrapper } from 'grow-shared-services';
import { ToastProvider } from '@components/ToastComponent/ToastProvider';
import { EnvProvider } from './components/envProvider/EnvProvider';
import { EnvConfig } from './components/envProvider/EnvProvider.types';
import { IntlProvider } from './i18n';
import { WRAPPER_PROJECT_NAME_INSTANCE } from './config/constants';
import ToggleMockWrapper from './components/toggleMockWrapper/ToggleMockWrapper';
import { GlobalStyle } from '../theme/GlobalStyle';
import Router from './Router';

export default function App(props: EnvConfig) {
  const { ONCUSTOMER_OPTIMIZELY_KEY } = props;
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-teams-management-mfe',
    seed: 'bees-hub-teams-management-mfe',
  });

  useFeatureToggleService().loadToggles();

  return (
    <ThemeProvider theme="grow">
      <GlobalStyle />
      <InitialDataWrapper
        projectName={WRAPPER_PROJECT_NAME_INSTANCE}
        optKey={ONCUSTOMER_OPTIMIZELY_KEY}
      >
        <ToggleMockWrapper>
          <EnvProvider env={props}>
            <StylesProvider generateClassName={generateClassName}>
              <IntlProvider>
                <BrowserRouter>
                  <ToastProvider>
                    <Router />
                  </ToastProvider>
                </BrowserRouter>
              </IntlProvider>
            </StylesProvider>
          </EnvProvider>
        </ToggleMockWrapper>
      </InitialDataWrapper>
    </ThemeProvider>
  );
}
