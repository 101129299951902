import React, {
  PropsWithChildren,
  createContext,
  ReactNode,
  useMemo,
  useContext,
  ComponentProps,
  useState,
} from 'react';
import { ToastComponent } from '@components/ToastComponent/ToastComponent';

type ToastProps = Omit<ComponentProps<typeof ToastComponent>, 'open' | 'setOpen'>;

interface ToastContextProps {
  addToast: (toastProps: ToastProps) => void;
}

const ToastContext = createContext<ToastContextProps>({} as ToastContextProps);

const ToastProvider = ({ children }: PropsWithChildren<ReactNode>) => {
  const [toastData, setToastData] = useState<ToastProps>();
  const [open, setOpen] = useState(true);

  const value = useMemo(() => {
    const addToast = (props: ToastProps) => {
      setToastData(props);
      setOpen(true);
    };
    return { addToast };
  }, []);

  return (
    <ToastContext.Provider value={value} data-testId="toast-provider">
      {children}
      {toastData && <ToastComponent {...toastData} open={open} setOpen={setOpen} />}
    </ToastContext.Provider>
  );
};

export const useToastContext = (): ToastContextProps => useContext(ToastContext);
export { ToastContext, ToastProvider };
