import styled from 'styled-components';

export const LoadingView = styled.div`
  left: calc(50% - 22px);
  top: calc(50% - 22px);
  position: absolute;
`;

export const ContainerBody = styled.div`
  display: flex;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;
