import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GROW_ORGANIZATION_ACTIVATION } from '@config/featureToggles';
import { isFeatureEnabled } from 'grow-shared-services';
import * as Styled from './Router.styles';

const BASE_NAME = '/beesgrow';

const TeamsMainPage = lazy(() => import('./pages/teamsMainPage/TeamsMainPage'));

const Router = (): JSX.Element => {
  const isGrowAdminOrganizationActivation = isFeatureEnabled(GROW_ORGANIZATION_ACTIVATION);

  return (
    <Styled.ContainerBody>
      <Suspense
        fallback={
          <Styled.LoadingView>
            <CircularProgress
              style={{ color: '#000000' }}
              data-testid="transition-spinner"
              disableShrink
            />
          </Styled.LoadingView>
        }
      >
        <Styled.Content>
          <Routes>
            <Route
              path={`${BASE_NAME}/teams`}
              element={
                isGrowAdminOrganizationActivation ? (
                  <TeamsMainPage data-testid="teams-main-page-route" />
                ) : (
                  <Navigate
                    to={`${BASE_NAME}/management`}
                    replace
                    data-testid="redirect-teams-to-management"
                  />
                )
              }
            />
          </Routes>
        </Styled.Content>
      </Suspense>
    </Styled.ContainerBody>
  );
};
export default Router;
