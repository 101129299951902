import MessageMap from '../i18n.d';

export const enUS: MessageMap = {
  TEST: {
    TRANSLATE: 'Translate',
    MISSING: 'Only English option available',
  },
  UNGROUPED_AGENTS_CARD: {
    UNGROUPED_AGENTS_WARNING_MESSAGE: 'There are some agents that may need allocation to groups',
    RELOCATE_BUTTON: 'Relocate',
    SELECT_ALL_BUTTON: 'Select all',
    SELECTED_TEXT: 'selected',
    UNGROUPED_AGENTS_TEXT: 'Ungrouped agents',

    NO_AGENTS: 'No agents found...',
    NO_AGENTS_UNGROUPED: 'It seems there are no ungrouped agents.',
    NO_AGENTS_DESCRIPTION: `This page will populate once they're available.`,

    ERROR_SERVICE: 'Service unavailable...',
    ERROR_SERVICE_DESCRIPTION:
      'We were unable to load the list of ungrouped agents. Please refresh the list.',
    REFRESH: 'Refresh list',
  },
  LOADING: {
    BEELOADING_TEXT: 'Processing…',
  },
  TEAMS_MAIN_PAGE: {
    TITLE: 'Teams',
    CREATE_GROUP: 'Create new group',
    NO_GROUPS_CREATED: 'No groups created yet.',
    NO_GROUPS_CREATED_DESCRIPTION:
      'Groups allow setting the audience, missions and more to multiple agents.',
    CANCEL: 'Cancel',
    CREATE: 'Create',

    PAGINATION_INFO: 'Displaying Y of X groups per page',
  },
  ORGANIZATION_CARD: {
    VENDORS: 'Vendors',
    ORG_INFO: {
      AGENTS: 'Agents',
      GROUPS: 'Groups',
      MISSIONS: 'Missions',
      TOOLTIP_INFO_MESSAGE: 'Total of active missions for the organization',
    },
    MY_BEES_DATA: 'My BEES Data',
    STRUCTURES: 'Structures',
  },
  GROUP_CARD: {
    AGENTS: 'Agents',
    MISSIONS: 'Missions',
    VENDORS: 'Vendors',
  },
  CREATE_GROUP_FORM: {
    FIELD_NAME: 'Name',
    FIELD_DESCRIPTION: 'Describe this group in a few words',
    FIELD_PLACEHOLDER: `Example: Partners' Growers`,
    FIELD_GROUP_NAME_REQUIRED: 'Enter a name',
    FIELD_GROUP_NAME_MUST_BE_UNIQUE: `There's already a group with this name, try another one.`,

    OPTIONAL: '(optional)',
    REMOVE_ALL: 'Remove all',
    USE_FIELD_ABOVE: 'Use the field above to add.',

    FIELD_AGENT: 'Agents',
    FIELD_AGENT_TITLE: 'Select agent',
    FIELD_AGENT_PLACEHOLDER: 'Search by name or email',
    FIELD_AGENT_EMPTY: 'No agents yet.',
    FIELD_AGENT_NO_UNGROUPED_AGENTS: 'All agents are already allocated.',
    FIELD_AGENT_NO_UNGROUPED_AGENTS_DESCRIPTION:
      'You can move agents from their current group details.',

    FIELD_MISSION: 'Missions',
    FIELD_MISSION_TITLE: 'Add mission',
    FIELD_MISSION_EMPTY: 'No missions yet.',
    FIELD_MISSION_SEARCH: 'Search mission by name',
    FIELD_MISSION_ERROR:
      "You've reached the limit of 50 missions. Group all local missions or distribute the missions into groups.",

    FIELD_AUDIENCE: 'Audience',
    FIELD_AUDIENCE_TITLE: 'Add vendor',
    FIELD_AUDIENCE_EMPTY: 'No vendors yet.',
    FIELD_AUDIENCE_SEARCH: 'Search vendor by name or ID',
    FIELD_AUDIENCE_ERROR: 'Select a vendor',
    FIELD_SEGMENT_ERROR: 'Insert a value or remove the condition',

    FIELD_KPIS: 'Performance targets',
    FIELD_KPIS_AHT: 'AHT',
    FIELD_KPIS_AHT_MEANING: 'Average handle time',
    FIELD_KPIS_AHT_DETAILS_MEANING:
      '"Average handle time". Calculated by the average time spent on calls.',
    FIELD_KPIS_TOPLINE_UPLIFT: 'Topline uplift ',
    FIELD_KPIS_TOPLINE_UPLIFT_MEANING:
      'Calculated by uplifted amount and percentage after a strike call and real order placement.',
    FIELD_KPIS_TOTAL_CALLS: 'Total calls',
    FIELD_KPIS_TOTAL_CALLS_MEANING: 'Total calls made by the agent.',
    FIELD_KPIS_HIT_RATE: 'Hit rate',
    FIELD_KPIS_HIT_RATE_MEANING:
      'Calculated by dividing the total of answered calls by the total number of calls made.',
    FIELD_KPIS_VISIBLE: 'KPI visible ',
    FIELD_KPIS_INVISIBLE: 'KPI invisible',
    FIELD_KPIS_DAILY: 'Daily',
    FIELD_KPIS_MONTHLY: 'Monthly',
    FIELD_KPIS_TOOLTIP:
      'Define if this KPI will be visible or not to the agents selected for this group.',
    FIELD_KPIS_EMPTY: 'No targets yet.',

    FIELD_SEARCH_NOT_FOUND: 'Nothing found with the terms searched.',
    ADD_ALL: 'Add all',

    ADD_CONDITION: 'Add condition',
    ADD_VALIDATOR: 'Add validator',
    REMOVE_CONDITION: 'Remove condition',
    ENTER_VALIDATOR: 'Enter validator',
    SEGMENT: 'Segment',
    SEARCH_CONDITION: 'Search condition',

    TOPLINE_GAP: 'Topline gap',
    MARKETPLACE: 'Marketplace',
    CHALLENGES: 'Challenges',
    SERVICES: 'Services',
    LOCAL_MISSIONS: 'Local missions',

    TOAST_SUCCESS_MESSAGE: 'Group successfully created. ',
    TOAST_ERROR_MESSAGE: 'Unabled to create the group. ',
    TOAST_ERROR_INVALID_ORGANIZATION:
      'The current organization is not available anymore. Get in touch with the support for more information. ',
    TOAST_ERROR_TRY_AGAIN: 'Try again',

    TOGGLE_ALL_LOCAL_MISSIONS: 'Group all Local Missions',
    TOOLTIP_ALL_LOCAL_MISSIONS:
      'This option groups all individual local missions into one, allowing you to prioritize them quicker.',
  },

  EDIT_GROUP_FORM: {
    TITLE: 'Edit group',
    SAVE: 'Save',
    CANCEL: 'Cancel',

    TOAST_SUCCESS_MESSAGE: 'The changes were successfully saved.',
    TOAST_ERROR_MESSAGE: 'Unable to save the changes.',
    TOAST_ERROR_TRY_AGAIN: 'Try again',
  },
  GROUP_DETAILS: {
    EDIT: 'Edit',
    DELETE: 'Delete',
    START_DATE: 'Start',
    END_DATE: 'End',
    END_DATE_VALUE: 'No end date',
  },

  DELETE_GROUP: {
    DELETE: 'Delete',
    CONFIRMATION_DELETE: 'Delete this group?',
    DELETE_MESSAGE: `The group will be deleted immediately, and this action can’t be undone.`,
    GO_BACK: 'Go back',
    TOAST_SUCCESS_MESSAGE: 'Group successfully deleted.',
    TOAST_FAILED_MESSAGE: 'Unable to delete the group.',
    TRY_AGAIN: 'Try again',
  },

  ERROR_PAGE_NOT_LOADED: {
    TITLE: 'Unable to load the page',
    TEXT: 'Our servers are temporarily busy. Please try again in a few minutes.',
    BUTTON: 'Try again',
  },
};
