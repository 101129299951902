/* eslint-disable no-console */
import React from 'react';
import { useFeatureToggleService } from 'admin-portal-shared-services';
import {
  GROW_ADMIN_TECH_3PD_LOCAL_MISSION_PERSONALIZATION,
  GROW_ORGANIZATION_ACTIVATION,
  TLP_CHECK_FEATURE_TOGGLES_ARE_LOADED,
} from '@config/featureToggles';

interface ToggleMockWrapperProps {
  children: JSX.Element;
}

const ToggleMockWrapper = ({ children }: ToggleMockWrapperProps): JSX.Element => {
  const featureToggleService = useFeatureToggleService();
  if (process.env.mockToggles) {
    featureToggleService.mockFeatureToggle(TLP_CHECK_FEATURE_TOGGLES_ARE_LOADED, true);
    featureToggleService.mockFeatureToggle(GROW_ORGANIZATION_ACTIVATION, true);
    featureToggleService.mockFeatureToggle(GROW_ADMIN_TECH_3PD_LOCAL_MISSION_PERSONALIZATION, true);
  }

  return children;
};

export default React.memo(ToggleMockWrapper);
