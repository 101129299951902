import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { createFeatureToggleServiceV2 } from 'admin-portal-shared-services';
import App from './App';
import { getPortalConfig } from './config/utils/functions';
import { axiosIntercept } from './config/axios/axiosInstance';
import { WRAPPER_PROJECT_NAME_INSTANCE } from './config/constants';

const sdkKey: string = getPortalConfig().OPTIMIZELY_KEY;

axiosIntercept();
createFeatureToggleServiceV2(WRAPPER_PROJECT_NAME_INSTANCE, sdkKey);

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
});

export const mount = [
  async () => {
    if (document?.getElementById('grow-container') !== null) {
      document.getElementById('grow-container').style.display = 'flex';
    }
  },
  reactLifecycles.mount,
];

export const unmount = [
  async () => {
    if (document.getElementById('grow-container')) {
      document.getElementById('grow-container').style.display = 'unset';
    }
  },
  reactLifecycles.unmount,
];

export const { bootstrap } = reactLifecycles;
